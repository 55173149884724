import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Badge } from '@abyss/web/ui/Badge';
import { Card } from '@abyss/web/ui/Card';
import { DataTable } from '@abyss/web/ui/DataTable';
import { Router } from '@abyss/web/ui/Router';
import React, { useState } from 'react';
import { AuditTrail } from '../AuditTrail';
import { tempData } from '../Detail/data';
import Timer from './Timer';
import { Grid } from '@abyss/web/ui/Grid/Grid';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Modal } from '@abyss/web/ui/Modal/Modal';
import { Button } from '@abyss/web/ui/Button/v1/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';

export const dummyData = [
  {
    fln: '',
    filename: '',

    submissionID: '1',

    docClass: 'TRACR',
    status: '',
    completedBy: 'Jane Doe',
    receivedDateTime: '',
    targetDateTime: '',
    updatedDateTime: '6',
    completedDateTime: '2024/01/17',
    member1: {
      id: '1',
      memberID: '1987',
      memberLastName: 'ted',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '12999993',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
    member2: {
      id: '1',
      memberID: '25',
      memberLastName: 'rob',
      policyNumber: '123456',
      dateOfBirth: '01/01/1990',
      dateOfService: '01/01/2021',
      editing: false,
      govcode: '9000123',
      mstate: 'MN',
      sourcecode: 'CDB',
      pedate: '02/02/2024',
    },
  },
];
export const Home = () => {
  const { navigate } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const actions = [
    {
      onClick: ({ row }) => {
        navigate(`/internal/detail/${row.original.submissionID}`);
      },
      // checkDisabled: (row) => {
      //   return row.original.filename === '65f3313570c8e52a2.txt';
      // },
      label: 'Review',
    },
    {
      onClick: () => setIsOpen(true),
      label: 'Reassign',
    },
  ];
  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Submission/Job ID',
        accessor: 'submissionID',
        hiddenDefaultFilters: [
          'greater',
          'greater-equal',
          'less',
          'less-equal',
        ],
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'File',
        accessor: 'filename',
        sortType: 'alphanumericCaseInsensitive',
      },

      {
        Header: 'Received Date/time',
        accessor: 'receivedDateTime',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'Target Date/time',
        accessor: 'targetDateTime',
        sortType: 'alphanumericCaseInsensitive',
      },
      {
        Header: 'Assigne To',
        accessor: 'assingneto',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          let badgeValues;

          switch (value) {
            case 'HOLD':
              badgeValues = {
                badgeLabel: value,
                variant: 'info',
                outline: true,
              };
              break;
            case 'InProgress':
              badgeValues = {
                badgeLabel: value,
                variant: 'success',
                outline: true,
              };
              break;
            case 'Rejected':
              badgeValues = {
                badgeLabel: value,
                variant: 'error',
                outline: true,
              };
              break;
            case 'SLA':
              badgeValues = {
                badgeLabel: value,
                variant: 'warning',
                outline: true,
              };
              break;
            case 'Delayed':
              badgeValues = {
                badgeLabel: 'Delayed',
                variant: 'warning',
                outline: true,
              };
              break;
            default:
              badgeValues = {
                badgeLabel: 'Not Started',
                variant: 'neutral',
                outline: true,
              };
              break;
          }

          const { badgeLabel, variant } = badgeValues;

          return <Badge variant={variant}>{badgeLabel}</Badge>;
        },
      },
      {
        Header: 'Time Duration',
        accessor: 'timeDuration',
        Cell: ({ row }) => {
          return (
            <>
              <Timer status={row.original.status} />
            </>
          );
        },
      },
    ];
  }, []);

  const tableData = useDataTable({
    initialData: dummyData, // implement when api will be ready
    initialColumns: columns,
    noDataMessage: 'No results',
    showDownloadButton: true,
    individualActions: actions,
  });
  const reassigneForm = useForm();
  const onReassignSubmit = (data) => {
    console.log('>>data', data);
  };
  const onReassignReset = () => {
    reassigneForm.reset();
  };

  return (
    <React.Fragment>
      <React.Fragment>
        <Router.MetaTags title="Home" />
        <React.Fragment>
          <FormProvider
            state={reassigneForm}
            onSubmit={onReassignSubmit}
            autoComplete="on"
          >
            <>
              <Modal
                title="Enter Assigne Details"
                model="Enter Assigne Details"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              >
                <Modal.Section>
                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        model="firstName"
                        label="First Name"
                        validators={{ required: `Error: Enter a FirstName` }}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        model="lastName"
                        label="Last Name"
                        validators={{ required: `Error: Enter a LastName` }}
                      />
                    </Grid.Col>
                  </Grid>
                  <Layout.Space />
                  <Layout.Group
                    alignLayout="right"
                    style={{ marginTop: '20px' }}
                  >
                    <Button variant="outline" onClick={onReassignReset}>
                      Reset
                    </Button>
                    <Button type="submit" onClick={onReassignSubmit}>
                      Submit
                    </Button>
                  </Layout.Group>
                </Modal.Section>
              </Modal>
            </>
          </FormProvider>
          <Card collapse header="Fallout Queue">
            <Card.Section
              css={{
                'abyss-card-section': {
                  paddingLeft: '10px',
                  paddingRight: '10px',
                },
              }}
            >
              <DataTable
                title="Search Results"
                tableState={tableData}
                css={{
                  'abyss-data-table-subheader-lower-container': { order: 1 },
                  'abyss-data-table-subheader-top-container': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: '20px',
                  },
                  'abyss-data-table-pagination-bottom-root': {
                    display: 'none',
                  },
                  'abyss-data-table-pagination-top-root': {
                    display: 'contents',
                  },
                  'abyss-data-table-header': { display: 'none' },
                }}
              />
            </Card.Section>
          </Card>
          <AuditTrail />
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  );
};
