import { useEffect, useMemo } from 'react';
import { useStopwatch } from 'react-timer-hook';

const Timer = ({ status }) => {
  const initialTimes = useMemo(
    () => ({
      InProgress: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
      Reassigned: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
      HOLD: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
      NEW: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
      SLA: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
      Delayed: new Date('2025-1-21 12:00:00.000').getTime() / 1000,
    }),
    []
  );

  const stopwatchOffset = useMemo(() => {
    const offset = new Date();
    const startTime = initialTimes[status];
    const currentTime = offset.getTime() / 1000;
    const remainingTime = currentTime - startTime;
    offset.setSeconds(offset.getSeconds() + remainingTime);
    return offset;
  }, [status, initialTimes]);

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  useEffect(() => {
    if (status === '' || status === 'undefined' || status === 'null') {
      pause();
    } else {
      start();
    }
  }, [status]);
  const totalMinutes = hours * 60 + minutes;
  const formattedSeconds = String(seconds).padStart(2, '0');

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '20px' }}>
        <span>{totalMinutes}</span>:<span>{formattedSeconds}</span>
        <br />
      </div>
    </div>
  );
};

export default Timer;
