import { DataTableData, useDataTable } from '@abyss/web/hooks/useDataTable';
import { DataTable } from '@abyss/web/ui/DataTable';
import {
  getFlnStorage,
  removeFromLocalStorage,
  setFlnStorage,
} from '@src/Utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { getMembersByFln } from './data';

const MemberInfo = ({ disabled, isReset }) => {
  const data = getMembersByFln(window.location.pathname.split('/')[3]);

  const [showRowIndex, setShowRowIndex] = useState<number | null>(null);

  const existinffln = window.location.pathname.split('/')[3];
  const [rowStates, setRowStates] = useState<object>({});

  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (showRowIndex !== null && existinffln) {
      setFlnStorage(existinffln, 'fln', existinffln);
    }
  }, [existinffln, showRowIndex]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: (
          <div className="header-memberID" style={{ color: '#003C71' }}>
            Member ID
          </div>
        ),
        accessor: 'memberID',
      },
      {
        Header: (
          <div className="header-memberLastName" style={{ color: '#003C71' }}>
            Member Last Name
          </div>
        ),
        accessor: 'memberLastName',
      },
      {
        Header: 'Date of Birth',
        accessor: 'dateOfBirth',
      },
      {
        Header: <div style={{ color: '#003C71' }}>Policy Number</div>,
        accessor: 'policyNumber',
      },
      {
        Header: 'Source Code',
        accessor: 'sourcecode',
      },
      {
        Header: 'Government Code',
        accessor: 'govcode',
      },
      {
        Header: 'Member State',
        accessor: 'mstate',
      },
      {
        Header: 'Date of Service',
        accessor: 'dateOfService',
      },
      {
        Header: 'Policy Effective Date',
        accessor: 'pedate',
      },
    ];
  }, []);

  const handleRowSelection = useCallback(
    (row) => {
      if (clicked && showRowIndex !== row.index) {
        return false;
      }
      if (row?.isSelected) {
        setRowStates(row.values);
        setFlnStorage(existinffln, 'selectedIndex', row.index); // Save it in localStorage
        setShowRowIndex(row.index); // Display row
      }
      return true;
    },
    [showRowIndex, existinffln, clicked]
  );

  const dataTableProps = useDataTable({
    initialColumns: columns,
    initialData: data || ({} as DataTableData),
    singleSelection: (row: any) => handleRowSelection(row),
    showPagination: true,
  });

  useEffect(() => {
    if (isReset) {
      const savedflndata = getFlnStorage(existinffln);
      const rowIndex = savedflndata?.selectedIndex;
      if (rowIndex !== null) {
        dataTableProps?.rows?.[rowIndex]?.toggleRowSelected(false);
        removeFromLocalStorage(existinffln);
        setClicked(false);
        setRowStates({});
      }
    }
  }, [isReset]);
  useEffect(() => {
    const savedflndata = getFlnStorage(existinffln);
    const savedIndex = savedflndata?.selectedIndex;
    const holdState = savedflndata?.holdState;

    if ((savedIndex !== null && savedIndex !== undefined) || holdState) {
      const rowIndex = parseInt(savedIndex, 10);
      dataTableProps?.rows?.[rowIndex]?.toggleRowSelected(true);

      setShowRowIndex(rowIndex);
      if (holdState === 'Hold') {
        setClicked(true);
      } else {
        setClicked(false);
      }
    }
  }, [dataTableProps, existinffln, clicked]);
  return (
    <React.Fragment>
      <DataTable
        tableState={dataTableProps}
        title=""
        css={{
          'abyss-data-table-header': { display: 'none' },
          'abyss-data-table-subheader-lower-container': { order: 1 },
          'abyss-data-table-pagination-bottom-root': {
            display: 'none',
          },
          'abyss-data-table-pagination-top-root': {
            display: 'contents',
          },
        }}
      />
    </React.Fragment>
  );
};

export { MemberInfo };
