import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Breadcrumbs } from '@abyss/web/ui/Breadcrumbs';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flyout } from '@abyss/web/ui/Flyout';
import { Grid } from '@abyss/web/ui/Grid';
import { Router } from '@abyss/web/ui/Router';
import {
  getFlnStorage,
  removeFromLocalStorage,
  removeKeyFromFlnStorage,
} from '@src/Utilities';
import React, { useEffect, useState } from 'react';
import { MemberInfo } from './MemberInfo';
import { updateStatus } from './data';

import { Document, Page, pdfjs } from 'react-pdf';

const StyledPage = ({ ...props }) => {
  const CustomPage = styled(Page, {
    '&.react-pdf__Page': {
      margin: '1em 0',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
      maxHeight: '400px',
      maxWidth: '708px',
    },
  });
  return <CustomPage {...props} />;
};
export const Detail = () => {
  const existinffln = window.location.pathname.split('/')[3];
  console.log('>>//', existinffln);

  const { getRouteParams, navigate } = useRouter();

  const initialsavedflndata = getFlnStorage(existinffln);

  const [isOnHold, setIsOnHold] = useState<any>(() => {
    const savedHoldState = initialsavedflndata?.status;
    return savedHoldState ? savedHoldState : null;
  });
  const [isReset, setIsReset] = useState<boolean>(false);
  const [released, setReleased] = useState<boolean>(false);
  const [url, setUrl] = React.useState<string>('');

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
  const [numberOfPages, setNumberOfPages] = React.useState<number>(20);

  const onLoadSuccess = ({ numPages }) => {
    return setNumberOfPages(numPages);
  };

  const onSubmit = () => {
    removeFromLocalStorage(existinffln);
    navigate('/internal/home');
  };
  const onReject = () => {
    removeFromLocalStorage(existinffln);
    removeKeyFromFlnStorage(existinffln, 'startTime');
    updateStatus(existinffln, 'Rejected');
    navigate('/internal/home');
  };

  const onHold = () => {
    setReleased(true);
    setIsOnHold('Hold');
    updateStatus(existinffln, 'Hold');
  };
  const onRelease = () => {
    setReleased(false);
    setIsOnHold('Released');
  };
  useEffect(() => {
    const savedflndata = getFlnStorage(existinffln);
    const holdState = savedflndata?.status;

    if (holdState === 'Hold') {
      setReleased(true);
    } else {
      setReleased(false);
    }
  }, [existinffln, released]);

  return (
    <React.Fragment>
      <Router.MetaTags title="Edit" />
      <React.Fragment>
        <Box color="$primary2" padding="$sm">
          <Breadcrumbs
            space="16px"
            items={[
              { title: 'Home', href: '/internal/home' },
              { title: 'Detail', href: '/internal/detail/:fln' },
            ]}
          />
        </Box>
        <Card collapse header="Edit">
          <Grid>
            <Grid.Col
              span={{ xs: 12 }}
              style={{
                padding: '40px',
                overflow: 'hidden',
              }}
            >
              <MemberInfo disabled={isOnHold} isReset={isReset} />
            </Grid.Col>
          </Grid>
          <Grid style={{ float: 'right', padding: '20px' }}>
            <Grid.Col>
              <Button
                size="$lg"
                onClick={onReject}
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Reject
              </Button>
            </Grid.Col>{' '}
            <Grid.Col>
              {released ? (
                <Button
                  size="$lg"
                  onClick={onRelease}
                  style={{
                    width: '120px',
                  }}
                >
                  Release
                </Button>
              ) : (
                <Button
                  size="$lg"
                  onClick={onHold}
                  style={{
                    width: '120px',
                  }}
                >
                  Hold
                </Button>
              )}
            </Grid.Col>{' '}
            <Grid.Col>
              <Button
                onClick={onSubmit}
                size="$lg"
                style={{
                  width: '120px',
                }}
                isDisabled={released}
              >
                Submit
              </Button>
            </Grid.Col>{' '}
          </Grid>
        </Card>
        <Flyout
          label="PDF"
          indent="30%"
          height="500px"
          width="700px"
          color="#003C71"
          position="right"
          css={{
            'abyss-flyout-root-container': {
              height: '20px',
              // top: '20%',
              maxWidth: '80%',
              maxHeight: '80%',
              // overflow: 'auto',
              border: '1px solid #ccc',
            },
          }}
        >
          <Document
            file="/UI_Access_Instructions.pdf"
            onLoadSuccess={onLoadSuccess}
          >
            {Array.from(new Array(numberOfPages), (el, index) => {
              return (
                <StyledPage
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  scale={1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              );
            })}
          </Document>
        </Flyout>
      </React.Fragment>
    </React.Fragment>
  );
};
