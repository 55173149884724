import { Router } from '@abyss/web/ui/Router';
import { AppWrapper } from '@src/components/AppWrapper/AppWrapper';
import { Header } from '@src/components/Header/Header';
import { Detail } from '@src/routes/Detail/Detail';
import { Home } from '@src/routes/Home/Home';
import { Logout } from '@src/routes/Logout';
import { Unauthorized } from '@src/routes/Unauthorized';
import React from 'react';

const AppRouter = () => {
  return (
    <React.Fragment>
      <Router.Routes title="{{title}} | UnitedHealth Group - Fallback">
        <Router.Route path="/" element={<Header />}>
          <Router.Route path="/internal" element={<AppWrapper />}>
            <Router.Route path="home" element={<Home />} />
            <Router.Route path="detail/:fln" element={<Detail />} />
          </Router.Route>
          <Router.Route path="/unauthorized" element={<Unauthorized />} />
          <Router.Route path="/logout" element={<Logout />} />
        </Router.Route>
      </Router.Routes>
    </React.Fragment>
  );
};
export { AppRouter };
